.main-container{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 64px - 42px - 40px - 54px);
}
.search-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 3px darkgray;
    margin-bottom: 10px;
    padding: 5px 20px;
    align-items: center;
    justify-content: center;
}
.search-component{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.list-container{
    display: flex;
    flex: 3;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 3px darkgray;
    flex-direction: column;
    overflow-y: auto;
    padding: 5px 20px;
}
.button-container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.modal-main-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modal-header{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #40a9ff;
    color: white;
    height: 30px;
}

.input-text{
    width: 350px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.input-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
}