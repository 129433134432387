.main-container{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 64px - 42px - 40px - 54px);
}
.data-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    margin-bottom: 10px;
    padding: 20px 10px;
    box-shadow: 2px 3px darkgray;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}
.upload-container{
    display: flex;
    flex: 8;
    flex-direction: column;
    background-color: white;
    padding: 20px 10px;
    border-radius: 5px;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    box-shadow: 2px 3px darkgray;
}
.fire-images{
    width: 36px;
    height: 36px;
}
.fire-images:hover{
    cursor: pointer;
}

.input-text{
    width: 350px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.input-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
}
.loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(50vh);
}

.loading span{
    font-size: 20px;
    font-weight: bold;
}

.img-table{
    display: flex;
    flex-direction: column;
    /* width: 800px; */
    /* transform: rotate(15deg); */
}
.img-row{
    display: flex;
    flex-direction: row;
}
.img-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
}
.img-col-fire{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img-col-fire div{
    background-color: rgba(255, 255, 255, 0.3);
    padding: 0px 5px;
    border-radius: 3px;
    font-weight: bold;
}