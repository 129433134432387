.login-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100vh);
    align-items: center;
    /* justify-content: center; */
}

.component-box > Input {
    width: 50%;
    margin: 5px;
}

.component-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 3px darkgrey;
    background-color: white;
    margin-top: 50px;
    padding: 0px 0px 40px 0px;
    width: 50%;
}

.title-bar{
    display: flex;
    height: 40px;
    margin-bottom: 10px;
    width: 100%;
    background-color: cornflowerblue;
}